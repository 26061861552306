import { stringify } from 'qs';
import { getToken, initialiseProgress, initialiseProgressJSON } from './helpers';

const authToken = getToken();


/* Gets */

export async function getDashboardData(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/course-progresses?${q}`, {
		headers: {
			Authorization: `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const data = await response.json();
	return data;
}	

export async function getProgressData(data) {
	var q = stringify({
		filters: {
			user: {
				$eq: data.user,
			},
			course: {
				documentId: {
					$eq: data.course
				}						
			}
		}
	});
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/course-progresses?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	const progressResponse = await response.json();
	return progressResponse;
}

export async function getCourseData(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/courses?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const courseResponse = await response.json();
	return courseResponse;
}

export async function getEnterpriseUsers(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const usersResponse = await response.json();
	return usersResponse;
}

export async function getCompanyData(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/enterprise-accounts?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const companyResponse = await response.json();
	return companyResponse;
}

export async function getAnswerScheme(q) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const answerSchemeResponse = await response.json();
	return answerSchemeResponse;
}


/* Creates */

export async function createProgressEntry(data) {	
	const q = stringify({
		populate: {
			lessons: {
				populate: ['lessonPages']
			}
		}
	});
	const course = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/courses/${data.courseId}?${q}`, {
		headers: {
			'Authorization': `Bearer ${authToken}`
		}
	})
	const courseResponse = await course.json();
	if(courseResponse.data) {
		const progressJSON = initialiseProgressJSON(courseResponse.data.lessons);
		const newProgressData = {
			data: {
				user: data.user,
				course: data.courseId,
				lessonProgress: progressJSON
			}
		}
		const saveProgress = initialiseProgress(newProgressData);
		return saveProgress;
	}
}

export async function createNewAnswerEntry(newAnswerData) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers`, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(newAnswerData)
	})
	const answerResponse = await response.json();
	return answerResponse;
}	


/* Updates */

export async function updateEnterpriseSettings(documentId, data) {	
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/enterprise-accounts/${documentId}`, {
		method: 'PUT',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			data: data
		})
	})
	const enterpriseSettingsResponse = await response.json();
	return enterpriseSettingsResponse;
}

export async function updateExerciseAnswers(documentId, data) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers/${documentId}`, {
		method: 'PUT',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			data: data
		})
	})
	const exerciseAnswersResponse = await response.json();
	return exerciseAnswersResponse;
}


/* User management */

export async function registerUser(data) {
	const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/auth/local/register`, {
		method: 'POST',
		headers: {
			'Strapi-Response-Format': 'v4',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const signupResponse = await response.json();
	return signupResponse;
}	

export async function inviteEnterpriseUser(data) {
	const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/inviteEnterpriseUser`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const inviteEnterpriseUserResponse = await response.json();
	return inviteEnterpriseUserResponse;
}

export async function verifyEnterpriseToken(data) {
	const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/verifyEnterpriseToken`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
	if(!response.ok) {
		throw new Error(response);
	}
	const verifyEnterpriseTokenResponse = await response.json();
	return verifyEnterpriseTokenResponse;
}