import { Button } from './atoms';

export function errorDialog({ error, resetErrorBoundary }) {
	
	function handleReportError() {
		window.location.replace('/dashboard');
	}
	
	return(
		<div className="dialog-shader visible">
			<div className="dialog">
				<div className="rows">
					<div className="row">						
						<div className="prose">	
						<h2>☹️ Úbbs! Something went wrong</h2>
						</div>					
					</div>		
					<div className="row">
						<div className="error-text">{error.message}</div>
					</div>
					<div className="row">
						<Button fullWidth={true} onClick={handleReportError}>Report error and go home</Button>
					</div>
				</div>
			</div>
		</div>
	)
}