import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import isTranslation from './locales/is.json';

i18n
	.use(initReactI18next)
	.init({
		fallbackLng: localStorage.getItem('lang') || 'en-gb',
		interpolation: {
			escapeValue: false,
		},
		resources: {
			en: { translation: enTranslation },
			is: { translation: isTranslation }
		}
	})

export default i18n;